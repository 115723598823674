.ql-snow .ql-editor .ql-code-block-container{
    background-color: #efefef;
    padding-top: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    overflow: visible;
}
.ql-snow .ql-tooltip[data-mode=video]::before {
    content: "Video / Loom";
}
.ql-editor li > .ql-ui:before {
    margin-right: 0.5em;
}
a[target="_blank"]::after {
    content: "";
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true' viewBox='0 0 24 24' fill='currentColor'%3E%3Cpath d='M6 6v2h8.59L5 17.59 6.41 19 16 9.41V18h2V6z'%3E%3C/path%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true' viewBox='0 0 24 24' fill='currentColor'%3E%3Cpath d='M6 6v2h8.59L5 17.59 6.41 19 16 9.41V18h2V6z'%3E%3C/path%3E%3C/svg%3E");
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 1em;
    height: 1em;
    color: inherit;
    background-color: currentColor;
    -webkit-transform: translate(0, 2px);
    -moz-transform: translate(0, 2px);
    -ms-transform: translate(0, 2px);
    transform: translate(0, 2px);
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.1, 0.8, 0.3, 1);
    transition: transform 0.3s cubic-bezier(0.1, 0.8, 0.3, 1);
    opacity: 0.8;
}
.ql-code-block {
    background-color: #efefef; /* <== change this color */
    color: #1d1c1d;
}
._loading_overlay_wrapper {
    position: relative;
}
@media screen and (max-width: 925px) {
    ._loading_overlay_wrapper {
        margin-bottom: 0;
    }
}
.ql-toolbar {
    /* <== using sticky here breaks the dropdowns */
    width: 100%;
}
@media screen and (max-width: 600px) {
    .ql-disabled .ql-editor{
        padding: 0
    }
}
.ql-editor {
    min-height: 6rem;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
}
[contenteditable="false"].ql-editor {
    padding: 0;
}
.ql-editor h1,
.ql-editor h2,
.ql-editor div,
.ql-editor ol,
.ql-editor table,
.ql-editor p {
    cursor: inherit;
}
.ql-disabled .ql-editor {
    min-height: auto
}
.editor-wrapper {
    z-index: 7;
}
#editorbox {
    border-top: 1px solid #ccc;
    border-bottom: 0;
}
@media screen and (max-width: 600px) {
    #editorbox {
        border-bottom: 1px solid #ccc;
    }
}

.ql-editor pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}
